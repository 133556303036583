//
// Header
//
.navbar-default {
  padding: 110px 0 0;
  transform: perspective(1px) skew(0deg, -2.5deg);
  margin-top: -110px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, 2.5deg);
  }
}

// Align menu right
.main-menu {
  float: right;
}

.nav > li > a {
  border-bottom: 3px solid transparent;
  font-family: $gentium-basic-bold;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 24px;
  padding: 0;
  text-transform: lowercase;
  transition: border .5s ease-in-out 0s;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  border-bottom: 3px solid $green-light;
}

// Menu toggle on mobile and tablet
.navbar-default .navbar-toggle {
  border-color: transparent;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: transparent;
  border-color: $grijs-licht;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: $wit--warm;
}

.navbar-toggle .icon-bar {
  width: 26px;
  height: 3px;
  border-radius: 0;
}

// Menu styles on mobile and tablet
@media all and (max-width: 999px) {
  .navbar-default .navbar-nav > li > a {
    border: 0;
  }

  .navbar-default .navbar-nav > li > a:focus,
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:focus,
  .navbar-default .navbar-nav > .active > a:hover {
    border-top: 0;
  }

  .nav > li > a {
    margin-top: 5px;
    padding: 10px 0 10px 15px;
    text-align: right;
  }
}

// sitename in navbar
.navbar-default .navbar-brand {
  color: $wit--warm;
  font-family: $eczar-extrabold;
  font-size: 22px;
  line-height: 34px;
  padding: 40px 15px 0;
  transition: color .5s ease-in-out 0s;

  &:hover,
  &:focus {
    border-bottom: 0;
  }
}

// Long company name, display it in smaller font sizes on mobile
@media all and (min-width: 375px) {
  .navbar-default .navbar-brand {
    font-size: 28px;
  }
}

@media all and (min-width: 768px) {
  .navbar-default .navbar-brand {
    font-size: 40px;
  }
}
