//
// Footer
//
.footer {
  background-color: $green-dark;
  color: $wit--warm;
  padding: 120px 0 180px;
  text-align: right;
  transform: skew(0deg, -2.5deg);
  margin-top: -70px;
  outline: 1px solid transparent;

  .container {
    transform: skew(0deg, 2.5deg);
  }

  li {
    border-right: 1px solid $beige-extra-licht;
    line-height: 1.1;
    padding: 0 10px 0 6px;
  }

  li:last-child {
    border-right: 0;
  }

  a {
    color: $wit--warm;
    border-bottom: 1px dotted $wit--warm;
  }

  a:hover {
    color: $wit--warm;
    border-bottom: 1px solid $wit--warm;
  }
}

@media all and (min-width: 768px) {
  .footer {
    min-height: 200px;
  }
}

// Opvullen van ontstane ruimte door skew van div
.footer-bottom {
  background: $green-dark;
  height: 72px;
  margin-top: -36px;
}
