//
// Variables
//

//
// Fonts
//
@font-face {
  font-family: 'Eczar Extra Bold';
  src: url('../fonts/eczar-extrabold-webfont.woff2') format('woff2'),
        url('../fonts/eczar-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gentium Basic Regular';
  src: url('../fonts/genbas-regular-webfont.woff2') format('woff2'),
        url('../fonts/genbas-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gentium Basic Bold';
  src: url('../fonts/genbas-bold-webfont.woff2') format('woff2'),
        url('../fonts/genbas-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gentium Basic Italic';
  src: url('../fonts/genbas-italic-webfont.woff2') format('woff2'),
        url('../fonts/genbas-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$eczar-extrabold: 'Eczar Extra Bold', serif;
$gentium-basic-regular: 'Gentium Basic Regular', serif;
$gentium-basic-bold: 'Gentium Basic Bold', serif;
$gentium-basic-italic: 'Gentium Basic Italic', serif;

$fa-font-path: '../fonts';

//
// Colors
//
// GBSG colors
$green-dark: #181f0d;
$green-dark--opaque: rgba($green-dark, .75);
$green-medium: #546f01;
$green-light: #a4bc00;
$green-dvm: #00a456; // rgb(0,164,86)
$green-dvm-darker: #008f4a;
$blue-dvm: #008be8; // rgb(0,139,232)
$orange-dvm: #f60;
$beige: #d8cabb;
$brown: #a88f6b;
$wit--warm: #fffaf4;

$beige-extra-licht: rgba($beige, .2);
// end GBSG colors
$paars: #7e247d;
$grijs-bruin: #80725e;
$grijs-bruin-medium: rgba($grijs-bruin, .85);
$grijs-bruin-extra-licht: rgba($grijs-bruin, .25);
$grijs-bruin-ultra-licht: rgba($grijs-bruin, .1);
$grijs-licht: #edeae6;
$grijs-licht-bootstrap: #ccc;
$grijs-medium: #777;
$grijs: #555;
$grijs-background: rgba($grijs-licht, .45);
$grijs-border: rgba($grijs-licht, 1);
$grijs-border-darker: rgba($grijs-medium, .45);
$geel: #ebd502;
$rood: #b20000;
$wit: #fff;
$black: #333;
$black--box-shadow: rgba(0, 0, 0, .075);

//
// Bootstrap overrides
// https://getbootstrap.com/customize/#less-variables
//

// Colors
$brand-primary: $green-light;

// Scaffolding
$body-bg: $wit--warm;
$text-color: $green-dark;
$link-color: $green-dark;
$link-hover-color: $green-dark;
$link-hover-decoration: none;

// Typography
$font-family-sans-serif: $gentium-basic-regular;
$font-size-base: 18px;
$line-height-base: 1.6;
$headings-color: $green-dark;

// Components
$border-radius-base: 3px;
$border-radius-large: 5px;
$border-radius-small: 2px;

// Buttons
$btn-primary-color: $wit--warm;
$btn-primary-border: $green-light;

// Forms
$input-border-focus: $green-light;

// Grid
$grid-float-breakpoint: 1000px; // Point at which the navbar becomes uncollapsed.

// Navbar
$navbar-height: 110px;
$navbar-default-color: $wit--warm;
$navbar-default-bg: $green-dark;
$navbar-default-link-color: $wit--warm;
$navbar-default-link-active-bg: none;
$navbar-default-link-active-color: $wit--warm;
$navbar-default-link-hover-color: $wit--warm;
$navbar-default-brand-hover-color: $wit--warm;

// Panel
$panel-bg: transparent;
$panel-default-text: $paars;
$panel-default-heading-bg: $grijs-bruin-ultra-licht;
$panel-inner-border: $grijs-bruin-extra-licht;
$panel-default-border: $grijs-bruin-extra-licht;
