//
// Generic styling rules
//

/* add padding since the Bootstrap navbar is fixed.*/
body {
  padding-top: 75px;
  position: relative;
}

/* Typography */
.sectie {
  h2 {
    font-family: $eczar-extrabold;
    font-size: 42px;
  }

  &-foto h2 {
    text-shadow: 2px 1px $beige;
  }
}

// Different styling for in-text links
.link--in-text,
%link--in-text {
  position: relative;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -2px;
    left: 0;
    background-color: $green-light;
    transition: height .5s ease-in-out 0s, background-color .5s ease-in-out 0s;
    z-index: -1;
  }

  &:hover:before {
    background: $green-light;
    height: 100%;
  }
}

// links to unsplash
.link--credits {
  bottom: -75px;
  font-size: 14px;
  position: absolute;
  right: 15px;

  &:hover {
    color: $wit--warm;
  }
}

// link to collapse more projects in projects section
.link--meer-projecten {
  border: 2px solid $green-light;
  display: inline-block;
  padding: 5px 15px;
  transition: background-color .5s ease-in-out 0s, color .5s ease-in-out 0s;
  width: 85%;

  &:hover {
    background: $green-light;
    color: $wit--warm;
  }
}

.product-anchor {
  display: block;
  position: relative;
  top: -175px;
}

.text--limited-width {
  max-width: 600px;
}

.paragraph--limited-width {
  max-width: 800px;
}

/* Buttons */
.btn {

  &:hover {
    border-bottom-width: 1px;
    transition: background .5s ease-in-out 0s, border .5s ease-in-out 0s;
  }

  &-default {
    background-color: transparent;
  }

  &-primary:hover {
    background-color: $green-medium;
    border-color: $green-medium;
  }
}

/* Asterisk only included for alignment purposes */
.glyphicon-asterisk.just-for-alignment {
  color: transparent;
}

/* General styles sections */
.sectie {
	padding: 40px 0 55px;

  // padding: 110px 0 0;
  // transform: skew(0deg, -2.5deg);
  // margin-top: -110px;
  //
  // .container {
  //   transform: skew(0deg, 2.5deg);
  // }
}

@media all and (min-width: 768px) {
	.sectie {
	  padding: 70px 0 105px;
	}
}

.sectie-grijs {
  background-color: $grijs-background;
  border-bottom: 1px solid $grijs-border;
  border-top: 1px solid $grijs-border;
  box-shadow: 2px 2px 2px $grijs-border;
}

.content-container-centered {
  margin-bottom: 45px; // equal to .product
}

.content-container-centered.last {
  margin-bottom: 0;
}

@media all and (min-width: 768px) {
  .content-container-centered {
    margin-bottom: 0;
  }
}

@media all and (min-width: 992px) {
  .content-container-centered {
    width: 80%;
  }
}

.sectie-foto {
  &:after {
    content: '';
    background-size: cover;
    opacity: .7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  .text-box {
    border-left: 3px solid $green-dark;
    padding: 0 6px;
    position: relative;
  }

  .text-box:after {
    background-color: $beige;
    content: '';
    opacity: .75;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}

// Intro sectie
.sectie-intro {
  padding: 115px 0 120px;
  transform: perspective(1px) skew(0deg, 2.5deg);
  margin-top: -37px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, -2.5deg);
  }

  &:after {
    background: url('../img/martin-sanchez-139280.jpg') no-repeat center center fixed;
  }
}

@media all and (min-width: 768px) {
	.sectie-intro {
	  padding: 230px 0 180px;
	}
}

// vertel en luister sectie
.sectie-vertel-luister {
  padding: 120px 0;
  transform: perspective(1px) skew(0deg, -2.5deg);
  margin-top: -35px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, 2.5deg);
  }
}

@media all and (min-width: 768px) {
	.sectie-vertel-luister {
	  padding: 180px 0;
    margin-top: -70px;
	}
}

.sectie-collectieve-intelligentie {
  padding: 100px 0 190px;
  transform: perspective(1px) skew(0deg, -2.5deg);
  margin-top: 0;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, 2.5deg);
  }

  &:after {
    background: url('../img/patrick-baum-194061.jpg') no-repeat center center fixed;
  }
}

@media all and (min-width: 768px) {
	.sectie-collectieve-intelligentie {
	  padding: 180px 0 250px;
	}
}

// vragen en weten sectie
.sectie-vragen-weten {
  background-color: $wit--warm;
  padding: 100px 0 120px;
  transform: perspective(1px) skew(0deg, 2.5deg);
  margin-top: -72px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, -2.5deg);
  }
}

@media all and (min-width: 768px) {
	.sectie-vragen-weten {
	  padding: 120px 0 180px;
	}
}

.sectie-grootschalig-luisteren {
  padding: 100px 0 190px;
  transform: perspective(1px) skew(0deg, -2.5deg);
  margin-top: 0;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, 2.5deg);
  }

  &:after {
    background: url('../img/max-boettinger-149143-bewerkt.jpg') no-repeat center center fixed;
  }
}

@media all and (min-width: 768px) {
	.sectie-grootschalig-luisteren {
	  padding: 180px 0 250px;
	}
}

// samen en mix & match sectie
.sectie-samen-mix-match {
  background-color: $wit--warm;
  padding: 100px 0 120px;
  transform: perspective(1px) skew(0deg, 2.5deg);
  margin-top: -72px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, -2.5deg);
  }
}

@media all and (min-width: 768px) {
	.sectie-samen-mix-match {
	  padding: 120px 0 180px;
	}
}

.sectie-narratief-onderzoek {
  padding: 100px 0 190px;
  transform: perspective(1px) skew(0deg, -2.5deg);
  margin-top: 0;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, 2.5deg);
  }

  &:after {
    background: url('../img/christopher-jenseth-182798.jpg') no-repeat center center fixed;
  }
}

@media all and (min-width: 768px) {
	.sectie-narratief-onderzoek {
	  padding: 180px 0 250px;
	}
}

// producten sectie
.sectie-producten {
  background-color: $wit--warm;
  padding: 100px 0 120px;
  transform: perspective(1px) skew(0deg, 2.5deg);
  margin-top: -72px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, -2.5deg);
  }

  .product {
    margin-bottom: 45px;
  }

  .container-producten .product:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-family: $eczar-extrabold;
  }

  h4 {
    color: $green-dark--opaque;
    font-family: $eczar-extrabold;
  }
}

@media all and (min-width: 768px) {
	.sectie-producten {
	  padding: 120px 0 180px;
	}
}

.sectie-projecten {
  background: $beige-extra-licht;
  padding: 100px 0 190px;
  transform: perspective(1px) skew(0deg, -2.5deg);
  margin-top: 0;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, 2.5deg);
  }

  .project {
    margin-bottom: 30px;
  }

  .last-row .project {
    margin-bottom: 0;
  }

  h3 {
    font-family: $eczar-extrabold;
  }

  h4 {
    color: $green-dark--opaque;
    font-family: $eczar-extrabold;
  }

  .project__afbeelding {
    float: right;
    margin: 5px 0 15px 15px;
    max-width: 40%;
  }

  .project__afbeelding img {
    max-height: 135px;
  }
}

@media all and (min-width: 768px) {
	.sectie-projecten {
	  padding: 180px 0 250px;
	}
}

.sectie-partners {
  background-color: $wit--warm;
  padding: 100px 0 120px;
  transform: perspective(1px) skew(0deg, 2.5deg);
  margin-top: -72px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, -2.5deg);
  }

  .partner {
    margin: 25px 0;
    height: 135px;
    position: relative;
  }

  .partner__logo {
    max-width: 225px;
    position: absolute;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    left: 0;
    right: 0;
    max-height: 135px;
  }
  
  .partner__logo--puzzling-panda {
    max-width: 165px;
  }

  .container-partners .partner:last-child {
    margin-bottom: 0;
  }
}

@media all and (min-width: 768px) {
	.sectie-partners {
	  padding: 120px 0 180px;

    .partner {
      margin-bottom: 0;
    }
	}
}

.sectie-nieuwsbrief {
  background: $beige-extra-licht;
  padding: 100px 0 190px;
  transform: perspective(1px) skew(0deg, -2.5deg);
  margin-top: 0;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, 2.5deg);
  }
}

@media all and (min-width: 768px) {
	.sectie-nieuwsbrief {
	  padding: 180px 0 250px;
	}
}

.sectie-contact {
  background: $wit--warm;
  padding: 100px 0 120px;
  transform: perspective(1px) skew(0deg, 2.5deg);
  margin-top: -72px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, -2.5deg);
  }

  h4 {
    margin-bottom: 0;
    margin-top: 0;

    + ul + h4 {
      margin-top: 30px;
    }
  }
}

@media all and (min-width: 768px) {
	.sectie-contact {
	  padding: 120px 0 180px;
	}
}

//
// blog
//
// list of items on /blog
.blog-list {
  padding: 120px 0;
  transform: perspective(1px) skew(0deg, -2.5deg);
  margin-top: -35px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, 2.5deg);
  }

  .blog-item {
    margin-bottom: 3.5em;
  }

  h3 {
    font-family: $eczar-extrabold;
    font-size: 32px;
    margin-bottom: 0;
    transition: color .5s ease-in-out 0s;
  }

  h3:hover,
  h3:focus {
    color: $green-light;
  }

  .meta {
    color: $green-dark--opaque;
    display: block;
    margin-bottom: 1rem;
  }
}

@media all and (min-width: 768px) {
	.blog-list {
	  padding: 180px 0;
    margin-top: -70px;
	}
}

// blog detail page
.sectie-intro--blog {
  padding: 115px 0 120px;
  transform: perspective(1px) skew(0deg, 2.5deg);
  margin-top: -37px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, -2.5deg);
  }

  .blog__meta {
    margin-bottom: 0;
  }

  .cta--header-subscribe {
    position: absolute;
    font-size: 16px;
    right: 15px;
    top: -30px;
  }
}

@media all and (min-width: 768px) {
	.sectie-intro--blog {
	  padding: 230px 0 180px;

    .cta--header-subscribe {
      top: -75px;
    }
	}
}

.blog-content {
  padding: 55px 0 120px;
  transform: perspective(1px) skew(0deg, -2.5deg);
  margin-top: -35px;
  outline: 1px solid transparent;

  .container {
    transform: perspective(1px) skew(0deg, 2.5deg);
  }

  .blog__share-buttons {
    margin: 15px 0;
    text-align: right;

    .instructie,
    .deelknoppen {
      display: inline-block;
    }

    .instructie {
      color: $green-dark--opaque;
      font-size: 16px;
      margin-right: 15px;
    }

    .deelknoppen a {
      color: $green-dark--opaque;
    }

    .deelknoppen a:hover,
    .deelknoppen a:focus {
      color: $green-dark;
    }
  }

  h4 {
    font-family: $eczar-extrabold;
    font-size: 24px;
    margin-top: 21px;
  }

  .blog__content a {
    @extend %link--in-text;
  }

  .blog__about-the-author {
    border-bottom: 2px solid $beige-extra-licht;
    color: $green-dark--opaque;
    margin-top: 20px;
    padding-bottom: 35px;

    h4 {
      color: $green-dark--opaque;
      margin-top: 0;
    }

    img {
      margin: 0 auto;
      max-width: 150px;
      opacity: .75;
      transition: opacity .5s ease-in-out 0s;
    }

    img:hover {
      opacity: 1;
    }
  }

  .cta--nieuwsbrief {
    background: $beige-extra-licht;
    margin: 20px 0;
    padding: 35px 50px;
    text-align: center;

    h4 {
      margin-top: 0;
    }
  }
}

@media all and (min-width: 768px) {
	.blog-content {
	  padding: 115px 0 180px;
    margin-top: -70px;
	}
}

/*
 * Lijst met features
 * Replace standard HTML bullets with Bootstrap Glyphicons
 * credit: http://output.jsbin.com/vowuz/1/
 */
.list-icons li {
  display: block;
  padding: 0 0 5px 20px;
  position: relative;
}

.list-icons li:before {
  font-family: 'Glyphicons Halflings';
  left: 0;
  position: absolute;
  top: -1px;
}

/* List icon styles */
.chevron-right li:before {
  content: '\e080';
}

.checkmark li:before {
  content: '\e067';
}

//
// Panels
//
.panel {
  border: 0;

  &-group {
    margin-bottom: 12px;
  }

  &-title {
    font-size: 16px;

    a,
    a:hover {
      border-bottom: 0;
    }

    .glyphicon-plus:before {
      content: '\2212';
    }

    .collapsed .glyphicon-plus:before {
        content: '\2b';
    }

  }

  &__list {
    margin-bottom: 0;
  }
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-width: 2px;
}
