//
// Forms
//
form .glyphicon-asterisk {
  color: $rood;
}

/* Contact en nieuwsbrief sectie */
#contact-form sup {
  font-size: 65%;
  top: -.6em;
}
